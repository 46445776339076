import React from 'react';
import styles from './style.module.scss';
import Link from 'next/link';
import Image from 'next/image';

import FooterStrip from '../strip-list';
import ClientSlider from './client-slider';
import FooterMenu from './footer-menu';

const Footer: React.FC = () => {
  return (
    <>
      <FooterStrip />

      <div className={styles.footerwrapper}>
        <ClientSlider />

        <section className={styles.footerSection}>
          <div className={styles.footerInner}>
            <div className="container-1240">
              <div className={styles.footerTop}>
                <FooterMenu />
              </div>
              <div className={styles.footerMiddle}>
                <div className="wdRow ">
                  <div className="wdCol-lg-5 wdCol-md-4">
                    <div className={styles.footerlocation}>
                      <p>
                        <span>Delivery Across India : </span> Ahmedabad,
                        Bangalore, Bhopal, Chandigarh, Chennai, Coimbatore,
                        Faridabad, Ghaziabad, Goa, Gurgaon, Hyderabad, Indore,
                        Jaipur and{' '}
                        <Link className="text-link-primary" href="/locations">
                          More Cities
                        </Link>
                      </p>
                    </div>
                  </div>
                  <div className="wdCol-lg-4 wdCol-md-4">
                    <div className={styles.footerpay}>
                      <p>We accept</p>
                      <ul>
                        <li>
                          <Image
                            src="/images/footer/footer-cards.png"
                            alt="footer-cards"
                            width={500}
                            height={60}
                          />
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="wdCol-lg-3 wdCol-md-4">
                    <div className={styles.footerpay}>
                      <p>Download App</p>
                      <ul>
                        <li>
                          <Link
                            target="_blank"
                            className={styles.footerapp}
                            href="https://play.google.com/store/apps/details?id=com.woodenstreet"
                          >
                            <Image
                              src="/images/footer/app-icon.png"
                              alt="app-icon"
                              width={150}
                              height={60}
                            />
                          </Link>
                          <Link
                            target="_blank"
                            className={styles.footerapp}
                            href="https://apps.apple.com/in/app/wooden-street/id1162068644?ls=1"
                          >
                            <Image
                              src="/images/footer/ios-icon.png"
                              alt="ios-icon"
                              width={150}
                              height={60}
                            />
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.footerBottom}>
                <div className={styles.footerBottomleft}>
                  <Link href="/" className={styles.footerLogo}>
                    <Image
                      src="/images/footer/logofooter.svg"
                      alt="foologofooter"
                      width={100}
                      height={60}
                    />
                  </Link>
                  <div className={styles.footercopyRight}>
                    <ul>
                      <li>
                        <Link href="/terms-of-use">Terms of Use</Link>
                      </li>
                      <li>
                        <Link href="/security-and-privacy">Security</Link>
                      </li>
                      <li>
                        <Link href="/return-and-refunds">Return & Refund</Link>
                      </li>
                      <li>
                        <Link href="/payment-policy">Payment Policy</Link>
                      </li>
                      <li>
                        <Link href="/grievance-redressal">Grievance Cell</Link>
                      </li>
                    </ul>
                    <p>© 2015-2024 Woodenstreet.com. All rights reserved.</p>
                    <p>The Woodenstreet Furnitures Private Limited</p>
                  </div>
                </div>
                <div className={styles.footerBottomright}>
                  <ul className={styles.footersocial}>
                    <li>
                      <Link
                        href={'https://www.instagram.com/woodenstreet/'}
                        target="_blank"
                      >
                        {' '}
                        <Image
                          src="/images/footer/insta.svg"
                          alt="insta"
                          width={30}
                          height={30}
                        />
                      </Link>
                    </li>
                    <li>
                      <Link
                        href={'https://www.facebook.com/Woodenstreet/'}
                        target="_blank"
                      >
                        {' '}
                        <Image
                          src="/images/footer/facebook.svg"
                          alt="facebook"
                          width={30}
                          height={30}
                        />
                      </Link>
                    </li>
                    <li>
                      <Link
                        href={'https://in.pinterest.com/woodenstreet/'}
                        target="_blank"
                      >
                        {' '}
                        <Image
                          src="/images/footer/pin.svg"
                          alt="pin"
                          width={30}
                          height={30}
                        />
                      </Link>
                    </li>
                    <li>
                      <Link
                        href={'https://www.youtube.com/c/woodenstreetofficial/'}
                        target="_blank"
                      >
                        {' '}
                        <Image
                          src="/images/footer/youtube.svg"
                          alt="youtube"
                          width={30}
                          height={30}
                        />
                      </Link>
                    </li>
                    <li>
                      <Link
                        href={
                          'https://in.linkedin.com/company/woodenstreet.com'
                        }
                        target="_blank"
                      >
                        {' '}
                        <Image
                          src="/images/footer/linkdin.svg"
                          alt="youtube"
                          width={30}
                          height={30}
                        />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Footer;
