import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import Link from 'next/link';
import Image from 'next/image';
import styles from './style.module.scss'; // Adjust the path as necessary
import { Autoplay } from 'swiper/modules';

const ClientSlider: React.FC = () => {
  const data = [
    { href: '/media', src: '/images/client/media-1.jpg', alt: 'media' },
    { href: '/media', src: '/images/client/media-2.jpg', alt: 'media' },
    { href: '/media', src: '/images/client/media-3.jpg', alt: 'media' },
    { href: '/media', src: '/images/client/media-4.jpg', alt: 'media' },
    { href: '/media', src: '/images/client/media-5.jpg', alt: 'media' },
    { href: '/media', src: '/images/client/media-6.jpg', alt: 'media' },
    { href: '/media', src: '/images/client/media-7.jpg', alt: 'media' },
    { href: '/media', src: '/images/client/media-8.jpg', alt: 'media' },
    { href: '/media', src: '/images/client/media-9.jpg', alt: 'media' },
    { href: '/media', src: '/images/client/media-10.jpg', alt: 'media' },
    { href: '/media', src: '/images/client/media-11.jpg', alt: 'media' },
    { href: '/media', src: '/images/client/media-12.jpg', alt: 'media' },
  ];

  /* spaceBetween: 0,
  centeredSlides: true,
  speed: 6000,
  autoplay: {
    delay: 1,
  },
  loop: true,
  slidesPerView:'auto',
  allowTouchMove: false,
  disableOnInteraction: true */

  return (
    <section className={styles.clientsection}>
      <div className="container-1200">
        <div className={styles.clientinner}>
          <div className="clientslider">
            <Swiper
              spaceBetween={0}
              centeredSlides={true}
              autoplay={{
                delay: 1,
                disableOnInteraction: true,
              }}
              speed={6000}
              loop={true}
              slidesPerView={'auto'}
              freeMode={true}
              allowTouchMove={false}
              breakpoints={{
                640: {
                  slidesPerView: 4,
                  spaceBetween: 10,
                },
                768: {
                  slidesPerView: 5,
                  spaceBetween: 12,
                },
                1024: {
                  slidesPerView: 7,
                  spaceBetween: 12,
                },
              }}
              modules={[Autoplay]}
              className="mySwiper"
              wrapperClass={'clientsliderWrapper'}
            >
              {data.map((media, index) => (
                <SwiperSlide key={index}>
                  <Link href={media.href} className={styles.clientcardimg}>
                    <Image
                      src={media.src}
                      alt={media.alt}
                      width={200}
                      height={100}
                      loading="lazy"
                    />
                  </Link>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ClientSlider;
